import React from "react"
import Layout from "../components/Layout"

const Legal =  () => {
    return (
        <Layout>
            <div className="max-w-4xl flex-grow mx-auto flex flex-col justify-around px-3 lg:px-0">
                <h1 className="text-3xl py-4">AVISO LEGAL APP</h1>
                <p>L.S.S.I. C.E.</p>
                <p>En cumplimiento de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio Electrónico (LSSI CE), informamos a los usuarios de los datos de la empresa propietaria de esta App:</p>
                <p>Denominación Social: GIA 2020 S.L</p>
                <p>Nombre Comercial: RECUPERATUSPUNTOS</p>
                <p>CIF / NIF: B 53598744</p>
                <p>Domicilio Social:  C/ ANTONIO MENA 76, 03201, ELCHE, ALICANTE.</p>
                <p>e-Mail: <a href="mailto:apprecuperatuspuntos@gmail.com">apprecuperatuspuntos@gmail.com</a></p>
                
                <p>Sitio web: http://recuperatuspuntos.app</p>
                <h2 className="text-xl py-2">OBJETO</h2>
                <p>GIA 2020 S.L, responsable de la APP, pone a disposición de los usuarios el presente documento con el que pretende dar cumplimiento a las obligaciones dispuestas en la Ley 34/2002, de Servicios de la Sociedad de la Información y del Comercio Electrónico (LSSI-CE), así como informar a todos los usuarios de la APP respecto a cuáles son las condiciones de uso de la APP.</p>
                <p>Toda persona que acceda a esta APP asume el papel de usuario, comprometiéndose a la observancia y cumplimiento riguroso de las disposiciones aquí dispuestas, así como a cualesquiera otra disposición legal que fuera de aplicación.</p>
                <p>GIA 2020 S.L se reserva el derecho a modificar cualquier tipo de información que pudiera aparecer en la APP, sin que exista obligación de preavisar o poner en conocimiento de los usuarios dichas obligaciones, entendiéndose como suficiente con la publicación en la APP.</p>
                <h2 className="text-xl py-2">LEY APLICABLE Y JURISDICCIÓN</h2>
                <p>Con carácter general las relaciones entre GIA 2020 S.L y los usuarios de sus servicios telemáticos, presentes en la web, se encuentran sometidas a la legislación y jurisdicción española.</p>
                <p>Las partes renuncian expresamente al fuero que les pudiera corresponder y someten expresamente a los Juzgados y Tribunales de ELCHE para resolver cualquier controversia que pueda surgir en la interpretación o ejecución de las presentes condiciones contractuales.</p>
                <h2 className="text-xl py-2">ACEPTACIÓN DEL USUARIO</h2>
                <p>Este Aviso Legal regula el acceso y utilización de la página web que GIA 2020 S.L pone a la disposición de los usuarios de Internet. Se considera usuario la persona que acceda, navegue, utilice o participe en los servicios y actividades de la página web.</p>
                <p>El usuario queda informado, y acepta, que el acceso a la presente web no supone, en modo alguno, el inicio de una relación comercial con GIA 2020 S.L.</p>
                <p>El acceso y navegación en el sitio web por parte del usuario supone la aceptación de la totalidad de las presentes Condiciones de Uso. En caso de desacuerdo con las condiciones debe abstenerse a usar el sitio web.</p>
                <h2 className="text-xl py-2">ACCESO A LA WEB</h2>
                <p>El acceso a la página web por parte de los usuarios es de carácter libre y gratuito. Algunos servicios pueden encontrarse sujetos a contratación previa del servicio.</p>
                <p>En caso de ser necesario que el usuario aporte datos personales para acceder a alguno de los servicios, la recogida y el tratamiento de los datos se realizará de conformidad con la normativa vigente, en concreto con el RGPD.</p>
                <p>Para más información, consulte nuestra Política de Privacidad http://recuperatuspuntos.app.</p>
                <h2 className="text-xl py-2">CONTENIDO Y USO</h2>
                <p>La visita al sitio web por parte del usuario deberá  hacerse de forma responsable y de conformidad a la legalidad vigente, la buena fe, el presente Aviso Legal y respetando los derechos de propiedad intelectual e industrial de titularidad de GIA 2020 S.L o cualquier otras personas físicas o jurídicas.</p>
                <p>El uso de cualquiera de los contenidos del sitio web con finalidades que sean o pudieran ser ilícitas queda totalmente prohibido, así́ como la realización de cualquier acción que cause o pueda causar daños o alteraciones de cualquier tipo no consentidas por GIA 2020 S.L , al sitio web o a sus contenidos.</p>
                <p>El titular del web no se identifica con las opiniones vertidas en el mismo por sus colaboradores. La empresa se reserva el derecho de efectuar sin previo aviso las modificaciones que considere oportunas en su Web, pudiendo cambiar, suprimir o añadir tanto los contenidos y servicios que se presten a través de la misma como la forma en la que estos aparezcan presentados o localizados en sus servidores.</p>
                <p>La visita al sitio web por parte del usuario deberá  hacerse de forma responsable y de conformidad a la legalidad vigente, la buena fe, el presente Aviso Legal y respetando los derechos de propiedad intelectual e industrial de titularidad de GIA 2020 S.L o cualquier otras personas físicas o jurídicas.</p>
                <p>El uso de cualquiera de los contenidos del sitio web con finalidades que sean o pudieran ser ilícitas queda totalmente prohibido, así́ como la realización de cualquier acción que cause o pueda causar daños o alteraciones de cualquier tipo no consentidas por GIA 2020 S.L , al sitio web o a sus contenidos.</p>
                <h2 className="text-xl py-2">PROPIEDAD INTELECTUAL E INDUSTRIAL</h2>
                <p>Los derechos de propiedad intelectual del contenido de las páginas web, su diseño gráfico y códigos son titularidad de GIA 2020 S.L y, por tanto, queda prohibida su reproducción, distribución, comunicación pública, transformación o cualquier otra actividad que se realice con los contenidos de la página web, ni siquiera aunque se citen las fuentes, salvo que se cuente con el consentimiento previo, expreso y por escrito de GIA 2020 S.L. Todos los nombres comerciales, marcas o signos distintos de cualquier clase contenidos en las páginas web de la empresa son propiedad de sus dueños y están protegidos por ley.</p>
                <p>GIA 2020 S.L no concede ninguna licencia o autorización de uso de ninguna clase sobre sus derechos de propiedad intelectual e industrial o sobre cualquier otra propiedad o derecho relacionado con el sitio web, y en ningún caso se entenderá́ que el acceso y navegación de los usuarios implica una renuncia, transmisión, licencia o cesión total ni parcial de dichos derechos por parte de GIA 2020 S.L.</p>
                <p>Cualquier uso de esos contenidos no autorizado previamente por parte de GIA 2020 S.L será considerado un incumplimiento grave de los derechos de propiedad intelectual o industrial y dará  lugar a las responsabilidades legalmente establecidas. GIA 2020 S.L se reserva el derecho de ejercitar frente al usuario las acciones judiciales y extrajudiciales que correspondan.</p>
                <h2 className="text-xl py-2">RESPONSABILIDAD Y GARANTÍAS</h2>
                <p>GIA 2020 S.L declara que ha adoptado las medidas necesarias que, dentro de sus posibilidades y el estado de la tecnología, permitan el correcto funcionamiento de su sitio web así́ como la ausencia de virus y componentes dañinos. Sin embargo, GIA 2020 S.L no puede hacerse responsable de las siguientes situaciones que se enumeran a título enunciativo, pero no limitativo:</p>
                <p>La continuidad y disponibilidad de los Contenidos.</p>
                <p>La ausencia de errores en dichos Contenidos ni la corrección de cualquier defecto que pudiera ocurrir.</p>
                <p>La ausencia de virus y/o demás componentes dañinos.</p>
                <p>Los daños o perjuicios que cause cualquier persona que vulnere los sistemas de seguridad de GIA 2020 S.L.</p>
                <p>El uso que los usuarios puedan hacer de los contenidos incluidos en el web. En consecuencia, GIA 2020 S.L no garantiza que el uso que los usuarios puedan hacer de los contenidos que en su caso se incluyan en el sitio web, se ajusten al presente aviso legal, ni que el uso del sitio web se realice de forma diligente.</p>
                <p>El uso por menores de edad del sitio web o del envío de sus datos personales sin el permiso de sus tutores, siendo los tutores responsables del uso que hagan de Internet.</p>
                <p>Los contenidos a los que el usuario pueda acceder a través de enlaces no autorizados o introducidos por usuarios mediante comentarios o herramientas similares.</p>
                <p>La introducción de datos erróneos por parte del usuario o de un tercero.</p>
                <p>GIA 2020 S.L podrá suspender temporalmente y sin previo aviso, la accesibilidad al sitio web con motivo de operaciones de mantenimiento, reparación, actualización o mejora. No obstante, siempre que las circunstancias lo permitan, GIA 2020 S.L comunicará al usuario, con antelación suficiente, la fecha prevista para la suspensión de los contenidos.</p>
                <p>Asimismo, de conformidad con los arts. 11 y 16 de la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio electrónico (LSSICE), GIA 2020 S.L se compromete a la eliminación o, en su caso, bloqueo de los contenidos que pudieran afectar o ser contrarios a la legislación vigente, los derechos de terceros o la moral y orden público.</p>
                <h2 className="text-xl py-2">POLÍTICA DE PRIVACIDAD</h2>
                <p>Puede obtener toda la información sobre nuestra Política de Privacidad consultando nuestra página web: https://www.recuperatuspuntos.app</p>
                <h2 className="text-xl py-2">COOKIES</h2>
                <p>GIA 2020 S.L utiliza cookies, a los efectos de optimizar y personalizar su navegación por el sitio web. Las cookies son ficheros físicos de información que se alojan en el propio terminal del usuario, la información recogida mediante las cookies sirve para facilitar la navegación del usuario por el portal y optimizar la experiencia de navegación. Los datos recopilados mediante las cookies pueden ser compartidos con los creadores de las mismas, pero en ningún caso la información obtenida por las mismas será  asociada a datos personales ni a datos que puedan identificar al usuario.</p>
                <p>Sin embargo, si el usuario no desea que se instalen cookies en su disco duro, tiene la posibilidad de configurar el navegador de tal modo que impida la instalación de estos archivos.</p>
                <p>Puede obtener toda la información sobre nuestra Política de Cookies consultando nuestra página web: https:// www.recuperatuspuntos.app</p>
                <h2 className="text-xl py-2">ENLACES (LINKS)</h2>
                <p>La presencia de enlaces (links) en la página web de GIA 2020 S.L hacia otros sitios de Internet tiene finalidad meramente informativa y en ningún caso suponen sugerencia, invitación o recomendación sobre los mismos. GIA 2020 S.L no asumirá  responsabilidad por los contenidos de un enlace perteneciente a un sitio web ajeno, ni garantizará la fiabilidad, exactitud, amplitud, veracidad, validez y disponibilidad técnica.</p>
                <p>En el caso de que en otros sitios web se establezcan enlaces a la página web de GIA 2020 S.L, no se entenderá́ que GIA 2020 S.L ha autorizado el enlace o el contenido del sitio web en el que se contiene el enlace, tampoco podrá́ incluirse en la página del enlace contenidos inapropiados, difamatorios, ilegales, obscenos o ilícitos, ni otros contenidos que sean contrarios a la legalidad vigente.</p>
                <p>GIA 2020 S.L se reserva la posibilidad de contactar con el propietario del sitio web en el que se establezca el enlace si considera que se incumple la normativa, así como de ejercitar las correspondientes acciones judiciales y extrajudiciales.</p>
                <h2 className="text-xl py-2">MODIFICACIÓN DE LAS CONDICIONES</h2>
                <p>GIA 2020 S.L se reserva expresamente el derecho a modificar unilateralmente, total o parcialmente, sin necesidad de previo aviso, el presente Aviso Legal. El usuario reconoce y acepta que es su responsabilidad revisar periódicamente el presente Aviso Legal.</p>
            </div>
        </Layout>
    )
}

export default Legal